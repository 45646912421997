import React from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@edx/react-common'
import { DogForm } from './DogForm.tsx'

interface DogFormDialogProps {
  dogId?: number
  onClose: () => void
}

export function DogFormDialog({ dogId, onClose }: DogFormDialogProps) {
  return (
    <Dialog open onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="bg-gradient-to-b from-doggradient-300 to-doggradient-100 shadow-md border-2 border-white backdrop-blur-[50px] rounded-3xl bg-[unset]">
        <DialogHeader>
          <DialogTitle className="text-2xl">{dogId ? 'Edit' : 'Add a'} Dog</DialogTitle>
          {/*<DialogDescription>Make changes to your profile here. Click save when you're done.</DialogDescription>*/}
        </DialogHeader>
        <DogForm dogId={dogId} onCancel={onClose} onComplete={onClose} />
      </DialogContent>
    </Dialog>
  )
}
