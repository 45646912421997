import { EdxApi, EdxApiTypes } from '@edx/api'
import { Environment } from '../../config/environment.ts'

export const edxApi = new EdxApi({
  baseURL: Environment.BASE_API_URL,
})

export const setEDXApiAuthHeader = (params: { token: string }) => {
  edxApi.instance.defaults.headers.common['Authorization'] = `Bearer ${params.token}`
}

export type DogList = EdxApiTypes.DogResponse[]
export type DogDetail = EdxApiTypes.DogResponse

export const getDogById = async (id: number): Promise<DogDetail> => {
  return edxApi.api.dogsDetail(id).then(({ data }) => data)
}

export const getDogList = async (): Promise<DogList> => {
  return edxApi.api.dogsList().then(({ data }) => data || [])
}
