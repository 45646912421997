import React from 'react'

interface AnimalAgeProps {
  dob?: string
}

export function AnimalAge({ dob }: AnimalAgeProps) {
  if (!dob) {
    return null
  }

  // 5 years old
  const dobDate = new Date(dob)
  const now = new Date()
  const age = now.getFullYear() - dobDate.getFullYear()
  const month = now.getMonth() - dobDate.getMonth()
  const day = now.getDate() - dobDate.getDate()

  let ageString = ''

  if (age > 20 || age < 0) return 'unknown age'

  if (age < 1) return '< 1 year old'

  if (month < 0 || (month === 0 && day < 0)) {
    ageString = `${age - 1} years old`
  } else {
    ageString = `${age} years old`
  }

  return <span>{ageString}</span>
}
