import React from 'react';
import { ReactNode } from 'react';
import BlurImage from '/svgs/blur-shape.svg';

interface BackgroundBlurProps {
    children?: ReactNode
  }

export function BackgroundBlur({children}: BackgroundBlurProps){

    return(
        <div className="fixed bottom-0 left-0 right-0 mx-auto max-w-[1460px] max-h-[811px] w-full h-full">
            <img className='h-full w-full object-cover' src={BlurImage}/>
            {children}
        </div>
    )

}