import { useNavigate } from 'react-router-dom'
import { ReactNode, useEffect, useLayoutEffect } from 'react'
import { useAuthWrapperContext } from '@edx/web-auth'
import { NavBar } from './NavBar.tsx'
import { edxApi, setEDXApiAuthHeader } from '../services/edx-api.ts'
import { setEcommerceApiAuthHeader } from '../services/ecommerce-api.ts'
import { BackgroundBlur } from './BackgroundBlur.tsx'

interface AppWrapperProps {
  children: ReactNode
}

export function AppWrapper({ children }: AppWrapperProps) {
  const { authState, logout, onAuthenticationError } = useAuthWrapperContext()
  const navigate = useNavigate()
  const links = [
    {
      title: 'Shop DNA Tests',
      url: 'https://www.alphadogdna.com/products/the-essential-dog-panel',
    },
    {
      title: 'Learning Center',
      url: 'https://www.alphadogdna.com/pages/learning-center',
    },
    {
      title: 'About Us',
      url: 'https://www.alphadogdna.com/pages/about-us',
    },
    {
      title: 'Contact Us',
      url: 'https://www.alphadogdna.com/pages/contact',
    },
  ]

  useLayoutEffect(() => {
    edxApi.instance.interceptors.response.use(
      (res) => res,
      (error) => {
        if (error?.response?.status === 401) {
          onAuthenticationError()
        }

        return Promise.reject(error)
      },
    )
  }, [])

  setEDXApiAuthHeader({ token: authState?.authToken ?? '' })

  setEcommerceApiAuthHeader({
    token: authState?.authToken ?? '',
  })

  return (
    <div className="flex flex-col h-screen">
      <div className="relative z-[11]">
        <NavBar
          links={links}
          onClickActivate={() => {
            navigate('/activation')
          }}
          onClickLogo={() => {
            navigate('/')
          }}
          onClickProfile={() => {
            navigate('/profile')
          }}
          onClickSignOut={() => {
            logout()

            setTimeout(() => {
              // refresh the page to clear the cache/state singeltons
              window.location.reload()
            }, 1000)
          }}
        />
      </div>

      <main className="flex-grow p-8 pt-16 bg-gradient-to-b from-doggradient-500 via-doggradient-300 via-81% to-white mt-[64px]">
        <>
          <div className="relative -z-10">
            <BackgroundBlur />
          </div>
          <div className="">{children}</div>
        </>
      </main>
    </div>
  )
}
