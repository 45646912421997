import { ecommerceApi } from '../services/ecommerce-api.ts'

export interface IStripeLineItem {
  price: string
  quantity: number
}

export const createCheckoutSession = (lineItems: IStripeLineItem[]) => {
  return ecommerceApi.stripe.createCheckoutSession(lineItems)
}
