import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { init as initFullStory } from '@fullstory/browser'
import { App } from './App.tsx'
import './index.css'
import { Environment } from '../config/environment.ts'

if (import.meta.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://310d35f8d12edbcdd654926b37a9c5e0@o4506792561213440.ingest.us.sentry.io/4507782214254592',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

  initFullStory({ orgId: Environment.FULLSTORY_ORG_ID || '0000', cookieDomain: window.location.hostname })
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
