import React from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@edx/react-common'
import { FaCloudUploadAlt } from 'react-icons/fa'
import { AvatarCropper } from './AvatarCropper.tsx'

interface AvatarCropperModalProps {
  dogId?: string
  onClose: () => void
  updateAvatar: (imgSrc: any) => void
}

export function AvatarCropperModal({ updateAvatar, onClose }: AvatarCropperModalProps) {
  return (
    <Dialog open onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="bg-gradient-to-b from-doggradient-300 to-doggradient-100 shadow-md border-2 border-white backdrop-blur-[50px] rounded-3xl bg-[unset]">
        <DialogHeader>
          <DialogTitle className="text-2xl">
            Photo Editor
          </DialogTitle>
        </DialogHeader>
        <AvatarCropper updateAvatar={updateAvatar} closeModal={onClose} />
      </DialogContent>
    </Dialog>
  )
}

