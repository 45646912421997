export const Environment = {
  AUTH_WEB_URL: import.meta.env.VITE_APP_AUTH_WEB_URL ?? 'https://test.auth.etalondx.com',
  BASE_API_URL: import.meta.env.VITE_APP_BASE_API_URL ?? 'https://localhost:5001',
  BASE_WEB_URL: import.meta.env.VITE_APP_BASE_WEB_URL ?? 'https://localhost:3000',
  ECOMMERCE_API_URL: import.meta.env.VITE_ECOMMERCE_API_URL ?? 'http://localhost:8080',
  FULLSTORY_ORG_ID: import.meta.env.VITE_FULLSTORY_ORG_ID ?? '17HZ9P',
  STRIPE_PUBLISHABLE_KEY: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY ?? '',
  RESULTS_PREFIX_URL: import.meta.env.VITE_RESULTS_PREFIX_URL ?? '',
  IMAGES_BASE_URL: import.meta.env.VITE_APP_IMAGES_BASE_URL ?? 'https://assets.etalondiagnostics.com',
}
