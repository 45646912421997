import React, { useMemo } from 'react'
import { useActivationScreenState } from '../state.ts'
import { getDivStyleForDogAvatarBg } from '../../../utils/get-div-style-for-dog-avatar-bg.ts'
import { AnimalAge } from '../../../components/AnimalAge.tsx'
import { DogDetail } from '../../../services/edx-api.ts'

interface ActivationCodesListProps {}

export function ActivationCodesList({}: ActivationCodesListProps) {
  const state = useActivationScreenState()

  const dogsById = useMemo(() => {
    return state.dogs.reduce(
      (acc, dog) => {
        acc[dog.animalId!] = dog
        return acc
      },
      {} as Record<string, DogDetail>,
    )
  }, [state.dogs, state.activationCodes])

  return (
    <div className="flex flex-col justify-center items-center w-full gap-4">
      {state.activationCodes.map((activationCode) => (
        <div className="w-full max-w-xl" key={activationCode.code}>
          <div className=" grid grid-cols-1 sm:grid-cols-2 ">
            <div className="flex flex-col text-dogred-700 text-center">
              <div className="text-lg font-light">Activation Code</div>
              <div className="text-2xl font-semibold">{activationCode.code} - {activationCode.test.label}</div>
            </div>

            {dogsById[activationCode.animalId] && (
              <div className="border bg-white rounded-3xl p-2 min-w-[300px] mx-auto">
                <div className="flex justify-between gap-6 w-full">
                  <div
                    className="h-24 w-36 border shadow bg-white rounded-2xl"
                    style={getDivStyleForDogAvatarBg(dogsById[activationCode.animalId].avatar?.url)}
                  />

                  <div className="flex-1 flex flex-col min-w-0 text-dogred-500">
                    <div className="text-lg font-semibold truncate">{dogsById[activationCode.animalId].name}</div>
                    <div className="truncate uppercase">{dogsById[activationCode.animalId].breed}</div>
                    <div className="font-light text-sm truncate">
                      <AnimalAge dob={dogsById[activationCode.animalId].dateOfBirth ?? ''} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
