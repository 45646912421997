import { Navigate, Route, Routes } from 'react-router-dom'
import { DashboardScreen } from './screens/DashboardScreen'
import { AppWrapper } from './components/AppWrapper.tsx'
import { TestProductScreen } from './screens/TestProductScreen.tsx'
import { ActivationScreen } from './screens/ActivationScreen'
import { useAuthWrapperContext } from '@edx/web-auth'

export function AppRoutes() {
  return (
    <AppWrapper>
      <Routes>
        <Route element={<ActivationScreen />} path="/activation" />
        <Route element={<TestProductScreen />} path="/products/test" />
        <Route element={<DashboardScreen />} path="/" />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </AppWrapper>
  )
}
