import { PixelCrop } from 'react-image-crop'

const TO_RADIANS = Math.PI / 180
const MAX_CANVAS_AREA = 16777216 // Maximum canvas area (width * height)

export async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
) {
  const ctx = canvas.getContext('2d')  
  if (!ctx) {
    throw new Error('No 2d context')
  }

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height

  const pixelRatio = window.devicePixelRatio

  let canvasWidth = Math.floor(crop.width * pixelRatio)
  let canvasHeight = Math.floor(crop.height * pixelRatio)

  // Check if canvas area exceeds the maximum allowed area
  const canvasArea = canvasWidth * canvasHeight
  if (canvasArea > MAX_CANVAS_AREA) {
    const scalingFactor = Math.sqrt(MAX_CANVAS_AREA / canvasArea)
    canvasWidth = Math.floor(canvasWidth * scalingFactor)
    canvasHeight = Math.floor(canvasHeight * scalingFactor)
  }

  canvas.width = canvasWidth
  canvas.height = canvasHeight

  ctx.scale(pixelRatio, pixelRatio)
  ctx.imageSmoothingQuality = 'high'
  ctx.save()

  ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height)

  ctx.restore()
}
