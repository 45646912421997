import { LoadingSpinner } from '@edx/react-common'
import { FaTimes } from 'react-icons/fa'
import { FaFilePdf } from 'react-icons/fa6'
import { DogCardsHorizontalList } from './components/DogCardsHorizontalList.tsx'
import { DogFormDialog } from '../../components/DogFormDialog'
import { useDashboardScreenState } from './state.ts'
import { useEffect, useState } from 'react'
import { MdEdit } from 'react-icons/md'
import { AnimalAge } from '../../components/AnimalAge.tsx'
import { useNavigate, Link } from 'react-router-dom'
import { DogButton } from '../../components/DogButton.tsx'
import BG_DOGS_W_SHADOW from '/dog-group-with-shadow.png'
import ALPHADOG_SQUARE from '/alphadog-square.png'
import { makeEdxImageUrl } from '../../utils/make-edx-image-url.ts'
import { Environment } from '../../../config/environment.ts'
import { ScreenTitle } from '../../components/ScreenTitle.tsx'

export function DashboardScreen() {
  const state = useDashboardScreenState()
  const navigate = useNavigate()
  useEffect(() => {
    state.actions.loadDogs()
  }, [])

  useEffect(() => {
    if (state.selectedDog?.dog) {
      state.actions.selectDog(state.selectedDog?.dog.id)
    }
  }, [state.showingDogForm])

  return (
    <>
      <div className="flex justify-center items-center">
        <div className="lg:grid lg:grid-cols-2 w-full xl:justify-items-center">
          <section className="flex flex-col items-center justify-center space-y-12 relative max-w-[600px] z-10">
            {!state.dogs.length && state.dogsLoading ? (
              <LoadingSpinner overlay />
            ) : (
              <>
                {state.dogs.length > 0 ? (
                  <>
                    <ScreenTitle>Your Dog{state.dogs.length > 1 ? `s` : ''}</ScreenTitle>

                    <DogCardsHorizontalList />
                  </>
                ) : (
                  <>
                    <h2 className="text-4xl font-semibold mb-2">It's kinda empty here.</h2>
                    <p className="mb-8">Add a dog, or activate a test below!</p>
                  </>
                )}
              </>
            )}

            <div className="flex flex-col space-y-4 w-full max-w-[300px] font-montserrat text-edxred-500">
              <DogButton onClickFn={() => state.actions.showDogForm()}>Add a Dog</DogButton>

              <DogButton
                onClickFn={() => {
                  navigate('/activation')
                }}
              >
                Activate a Test
              </DogButton>

              <DogButton
                onClickFn={() => window.open('https://www.alphadogdna.com/products/the-essential-dog-panel', '_blank')}
              >
                Purchase Test
              </DogButton>
            </div>
          </section>

          <section className="flex items-center justify-self-stretch justify-center mt-[-476px] lg:mt-0 ">
            {state.selectedDog && (
              <div className="bg-gradient-to-b from-doggradient-300/80 to-doggradient-100/80 shadow-md border-2 border-white backdrop-blur-[10px] rounded-3xl p-8 w-full max-w-[500px] relative flex flex-col space-y-6 z-10">
                {state.selectedDog.loading && <LoadingSpinner />}

                <div className="absolute top-5 right-5 cursor-pointer flex gap-4 text-gray-600 text-2xl">
                  <div
                    className="text-dogred-500"
                    onClick={() => {
                      state.actions.showDogForm(state.selectedDog?.dog?.id)
                    }}
                  >
                    <MdEdit />
                  </div>

                  <div
                    className="text-dogred-500"
                    onClick={() => {
                      state.actions.selectDog(undefined)
                    }}
                  >
                    <FaTimes />
                  </div>
                </div>

                <div className="flex justify-center">
                  <div
                    className="bg-gray-300 rounded w-48 h-32"
                    style={{
                      backgroundImage: `url(${makeEdxImageUrl(state.selectedDog?.dog?.avatar?.url) || 'https://placehold.co/216x144?text=No+Photo'})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  ></div>
                </div>

                <div className="font-semibold text-2xl text-dogred-500 text-center">{state.selectedDog.dog?.name}</div>

                <div className="font-medium text-center text-dogred-500 text-sm mb-4">
                  <div>
                    <AnimalAge dob={state.selectedDog.dog?.dateOfBirth ?? ''} />
                  </div>
                  <div>{state.selectedDog.dog?.breed}</div>
                  <div>{state.selectedDog.dog?.discipline}</div>
                </div>

                {state.selectedDog.dog?.diagnosticsReportUrl && (
                  <Link
                    className="font-montserrat text-edxred-500"
                    to={`${Environment.RESULTS_PREFIX_URL}${state.selectedDog.dog?.diagnosticsReportUrl}`}
                    target="_blank"
                  >
                    <DogButton>Diagnostic Report</DogButton>
                  </Link>
                )}
                {!state.selectedDog.dog?.diagnosticsReportUrl && (
                  <DogButton classNames="font-montserrat text-edxred-500" disabled={true}>
                    Diagnostic Report
                  </DogButton>
                )}

                <div className="flex justify-center">
                  <FaFilePdf className="text-3xl" />
                </div>
              </div>
            )}
            <img className="hidden lg:block fixed h-[70%] top-[158px]" src={ALPHADOG_SQUARE} alt="Etalon" />
          </section>
        </div>
      </div>

      {state.showingDogForm && (
        <DogFormDialog dogId={state.showingDogForm?.dogId} onClose={state.actions.closeDogForm} />
      )}
    </>
  )
}
