import React from 'react'
import { FaSearch } from 'react-icons/fa'
import { useDashboardScreenState } from '../state.ts'
import { getDivStyleForDogAvatarBg } from '../../../utils/get-div-style-for-dog-avatar-bg.ts'

export function DogCardsHorizontalList() {
  const state = useDashboardScreenState()

  return (
    <div className="w-full overflow-x-scroll">
      <div className="flex overflow-x-scroll pb-8 hide-scroll-bar">
        <div className="flex flex-nowrap">
          {state.dogs
            .sort((a, b) => (a.dateCreated! > b.dateCreated! ? -1 : 1))
            .map((dog) => (
              <div
                className="inline-block px-3"
                key={dog.id}
                onClick={() => {
                  state.actions.selectDog(dog.id)
                }}
              >
                <div
                  className="cursor-pointer w-48 h-32 max-w-xs overflow-hidden rounded-lg shadow-md bg-gray-300/30 hover:shadow-xl transition-shadow duration-300 ease-in-out relative"
                  style={getDivStyleForDogAvatarBg(dog.avatar?.url)}
                >
                  {state.selectedDog?.dog?.id !== dog.id && (
                    <div className="absolute bottom-2 right-2 bg-white rounded-full w-8 h-8 flex items-center justify-center">
                      <FaSearch />
                    </div>
                  )}
                </div>

                <div className="capitalize text-dogred-500 text-center pt-3 font-semibold text-xl">{dog.name}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
