import { create } from 'zustand'
import { DogDetail, DogList, getDogList } from '../../services/edx-api.ts'
import { getActivationCodeDetail, submitActivationCodes, TTest } from '../../services/activation-api.ts'
import { cloneDeep } from 'lodash-es'

export type ActivationCode = {
  animalId: string
  code: string
  test: TTest
}

export interface ActivationScreenState {
  actions: {
    __reset__: () => void
    addActivationCode: (activationCode: ActivationCode) => void
    addDog: (dog: DogDetail) => void
    cancelActivationCode: () => void
    loadDogs: () => Promise<void>
    setActivationCodeInputValue: (value: string) => void
    submitActivationCodes: () => Promise<void>
    verifyActivationCode: (code: string) => Promise<void>
  }
  activationCodes: ActivationCode[]
  activationCodeInputError: string | null
  activationCodeInputLoading: boolean
  activationCodeInputValue: string | null
  dogs: DogList
  dogsLoading: boolean
  showSelectDogDialog?: {
    code: string
    test: TTest
  }
  submittingActivationCodes: boolean
  submitActivationCodesResult?: {
    success: boolean
    message: string
  }
}

const INITIAL_STATE = {
  activationCodes: [],
  activationCodeInputError: null,
  activationCodeInputLoading: false,
  activationCodeInputValue: null,
  dogs: [],
  dogsLoading: false,
  submittingActivationCodes: false,
  submitActivationCodesResult: undefined,
}

export const useActivationScreenState = create<ActivationScreenState>((set, get) => ({
  ...cloneDeep(INITIAL_STATE),

  actions: {
    __reset__: () => set({ ...cloneDeep(INITIAL_STATE) }),
    addActivationCode: (activationCode) => {
      set({
        activationCodes: [...get().activationCodes, activationCode],
        activationCodeInputValue: null,
        showSelectDogDialog: undefined,
      })

      get().actions.loadDogs()
    },

    addDog: (dog) => {
      set({
        dogs: [...get().dogs, dog],
      })
    },

    cancelActivationCode: () => {
      set({
        activationCodeInputValue: null,
        showSelectDogDialog: undefined,
      })
    },

    loadDogs: async () => {
      set({
        dogsLoading: true,
      })

      try {
        getDogList()
          .then((dogs) => {
            set({
              dogs,
              dogsLoading: false,
            })
          })
          .catch(() => {
            set({
              dogsLoading: false,
            })
          })
      } catch (err) {
        console.error('Error loading dogs', err)
      }
    },

    setActivationCodeInputValue: (value) => {
      const sanitizedValue = value.toUpperCase().trim().slice(0, 6)

      set({
        activationCodeInputError: null,
        activationCodeInputValue: sanitizedValue,
      })

      if (sanitizedValue.length === 6) {
        get().actions.verifyActivationCode(sanitizedValue)
      }
    },

    submitActivationCodes: async () => {
      set({
        submittingActivationCodes: true,
        submitActivationCodesResult: undefined,
      })
      try {
        await submitActivationCodes(
          get().activationCodes.map(({ animalId, code }) => ({
            activationCode: code,
            animalId: animalId,
          })),
        )

        await get().actions.loadDogs()

        set({
          submitActivationCodesResult: {
            success: true,
            message: 'Tests have been successfully activated.',
          },
        })
      } catch (err) {
        console.error('Error submitting activation codes', err)

        set({
          submitActivationCodesResult: {
            success: false,
            message: 'An error occurred while submitting the activation codes, please try again.',
          },
        })
      } finally {
        set({
          submittingActivationCodes: false,
        })
      }
    },

    verifyActivationCode: async (code) => {
      set({
        activationCodeInputLoading: true,
      })

      const { errorMesaage, test, valid } = await getActivationCodeDetail(code)

      if (valid && test) {
        set({
          showSelectDogDialog: {
            code,
            test,
          },
        })
      } else {
        set({
          activationCodeInputError:
            errorMesaage ?? 'An error occurred while verifying the activation code, please try again.',
        })
      }

      set({
        activationCodeInputLoading: false,
      })
    },
  },
}))
