import React, { useState } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogClose, Checkbox, Input, cn } from '@edx/react-common'
import { DogButton } from '../DogButton.tsx'

interface TosAgreementDialogProps {
  onClickAcceptAgreement(args: { name: string }): void
}

const TOS_TEXT = `
<div class="my-2">
Dear AlphaDog Client,
</div>

<div class="my-2">
Thank you for joining AlphaDogDNA’s research and discovery community! At AlphaDogDNA we are committed to our clients and the health of their horses. As you know, we are also committed to protecting our clients, their data and for all of the good folks collaborating with us. We therefore ask you to review the following legalese to make sure we are all in agreement as we move forward on this exciting endeavor. We are providing the opportunity for you to grant permission and voluntarily share your horse’s data and certain of your personal data with AlphaDogDNA clients who have given permission and chosen to share their data - a whole new way to connect with your herd!
</div>
<div class="my-4 italic">
~ Team AlphaDogDNA by Etalon
</div>

<p class="my-2 font-bold">
By clicking “I agree”, you agree to the terms of service (“Terms of Service”) set forth in the following link:
</p>

<a class="text-blue-600" href="https://www.alphadogdna.com/pages/terms-of-service-privacy-policy" target="_blank">https://www.alphadogdna.com/pages/terms-of-service-privacy-policy</a>

<div class="my-2">
    <p>We also encourage you to review our <a href="#">Privacy Policy</a>.</p>
</div>

<div class="my-2">
    <h1 class="font-bold text-lg">Etalon Terms of Service</h1>
    <h1 class="font-medium">Last Updated: June 28, 2023</h3>
    <p class="important">THESE TERMS OF SERVICE CONTAIN A MANDATORY ARBITRATION OF DISPUTES PROVISION THAT REQUIRES THE USE OF ARBITRATION TO RESOLVE DISPUTES RATHER THAN JURY TRIALS OR CLASS ACTION LAWSUITS. THESE TERMS OF SERVICE ALSO LIMIT ETALON’S LIABILITY. YOU ARE URGED TO READ THESE TERMS CAREFULLY.</p>
    
    <h2 class="font-bold">Welcome to Etalon’s Terms of Service</h2>
    <p>These Terms of Service (the “Terms”) make up the agreement between Etalon, a California corporation (“Etalon” or “Company” or “us” or “we”) and you (“you” or “Customer”). These Terms govern your use and access to Etalon’s products, services, testing, software, databases, and website (including but not limited to text, graphics, images, and other material and information) as accessed from time to time by you, regardless of whether the use is in connection with an account or not (collectively, the “Services”). Etalon’s website is found at <a href="http://www.etalondx.com">www.etalondx.com</a> (the “Site”).</p>
    <p>Read the Terms carefully, and any policies, guidelines, or rules applicable to such Services, as they constitute a legal agreement between Etalon and you. We also encourage you to review our <a href="#">Privacy Policy</a> for additional information. Of course, if you do not agree with our Terms or any other policies, then do not use the Services. You can delete your account from your Account Settings at any time.</p>
</div>

<div class="my-2">
    <h2 class="font-bold">Who Can Use the Services</h2>
    <p>We believe everyone should be able to access, understand, and benefit from their animal’s genetic characteristics. That said, there are a few restrictions about who can use our Services. By using the Services or creating an account, you represent, warrant and agree that:</p>
    <ul>
        <li>You are willing and legally capable of forming a binding contract with Etalon;</li>
        <li>You are not a person barred from receiving the Services under the laws of the jurisdiction from which you use the Services, by contract or other restriction; and</li>
        <li>Any animal sample you provide to us is either of an animal you own or you are legally authorized by the animal’s owner to provide the sample.</li>
    </ul>
</div>

<div class="my-2">
    <h2 class="font-bold">Accounts</h2>
    <h1 class="font-medium">Account Creation</h3>
    <p>In order to use certain features of the Site and to purchase the Services, you must register for an account (“Account”) and provide certain information about yourself and your animal as prompted by the account registration form. You represent and warrant that:</p>
    <ul>
        <li>All required information you submit is truthful and accurate;</li>
        <li>You will maintain the accuracy of such information.</li>
    </ul>
    <p>You may delete your Account at any time, for any reason, by following the instructions on the Site. The Company may suspend or terminate your Account for any reason or no reason at all at any time in accordance with Section 15.</p>
    
    <h1 class="font-medium">Account Responsibilities</h3>
    <p>You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account. You agree to immediately notify Company of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security. Company cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.</p>
</div>

<div class="my-2">
    <h2 class="font-bold">Access to the Site</h2>
    <h1 class="font-medium">License</h3>
    <p>Subject to these Terms, the Company grants you a non-transferable, non-exclusive, revocable, limited license to use and access the Site.</p>

    <h1 class="font-medium">Certain Restrictions</h3>
    <p>The rights granted to you in these Terms are subject to the following restrictions:</p>
    <ul>
        <li>You shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site, whether in whole or in part, or any content displayed on the Site;</li>
        <li>You shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site;</li>
        <li>You shall not access the Site in order to build a similar or competitive website, product, or service; and</li>
        <li>Except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means.</li>
    </ul>
    <p>Unless otherwise indicated, any future release, update, or other addition to functionality of the Site shall be subject to these Terms. All copyright and other proprietary notices on the Site (or on any content displayed on the Site) must be retained on all copies thereof.</p>
    
    <h1 class="font-medium">Modification</h3>
    <p>The Company reserves the right, at any time, to modify, suspend, or discontinue the Site (in whole or in part) with or without notice to you. You agree that the Company will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Site or any part thereof.</p>
    
    <h1 class="font-medium">No Support or Maintenance</h3>
    <p>You acknowledge and agree that the Company will have no obligation to provide you with any support or maintenance in connection with the Site.</p>
    
    <h1 class="font-medium">Ownership</h3>
    <p>You acknowledge that all the intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Site and its content are owned by the Company, the Company’s subcontractors, or the Company’s suppliers. Neither these Terms (nor your access to the Site) transfers to you or any third party any rights, title, or interest in or to such intellectual property rights, except for the limited access rights expressly set forth in Section 3(a). The Company, its subcontractors, and its suppliers reserve all rights not granted in these Terms. There are no implied licenses granted under these Terms.</p>
    
    <h1 class="font-medium">Feedback</h3>
    <p>If you provide the Company with any feedback or suggestions regarding the Site (“Feedback”), you hereby assign to the Company all rights in such Feedback and agree that the Company shall have the right to use and fully exploit such Feedback and related information in any manner it deems appropriate. The Company will treat any Feedback you provide to the Company as non-confidential and non-proprietary. You agree that you will not submit to the Company any information or ideas that you consider to be confidential or proprietary.</p>
</div>

<div class="my-2">
    <h2 class="font-bold">User Content</h2>
    <h1 class="font-medium">User Content</h3>
    <p>“User Content” means any and all information, content, and samples that a user submits to, or uses with, the Site, including but not limited to a user’s personal information (“Personal Information”) and all information and samples provided to us about an animal, including but not limited to ownership, lineage, characteristics, and traits, and the animal’s genetic sample (collectively, the “Animal Information”). You are solely responsible for your User Content. You assume all risks associated with use of your User Content, including any reliance on its accuracy, completeness, or usefulness by us or others, or any disclosure of your User Content that personally identifies you or any third party. You hereby represent and warrant that your User Content does not violate our Acceptable Use Policy (defined in Section 4(e)). You may not represent or imply to others that your User Content is in any way provided, sponsored, or endorsed by the Company. Since you alone are responsible for your User Content, you may expose yourself to liability if, for example, your User Content violates the Acceptable Use Policy. The Company is not obligated to back up any User Content, and your User Content may be deleted at any time without prior notice. You are solely responsible for creating and maintaining your own backup copies of your User Content if you desire.</p>
    
    <h1 class="font-medium">Use of Personal Information</h3>
    <p>You agree that all of your Personal Information may be used by us in connection with creating, authenticating, operating, maintaining, and closing your customer account (including an online account and any mobile account), managing logins, processing payments, providing the Services, delivering results to you, complying with applicable laws and regulations, and complying with these Terms.</p>
    
    <h1 class="font-medium">Use of Animal Information</h3>
    <p>You agree that all Animal Information may be used by us in connection with:</p>
    <ul>
        <li>Analyzing and testing the Animal Information;</li>
        <li>Advancing our research into animal genetic testing and related fields;</li>
        <li>Improving our processes, systems, procedures, testing, reports, and website;</li>
        <li>Populating our database of animal characteristics;</li>
        <li>Providing the Services; and</li>
        <li>Complying with these Terms.</li>
    </ul>
    <p>You grant to the Company, its affiliated companies, sublicensees, subcontractors, successors, and assigns a perpetual, irrevocable, worldwide, royalty-free, and non-exclusive license to reproduce, adapt, modify, translate, publish, publicly perform, publicly display, distribute, reproduce, edit, reformat, and create derivative works from the Animal Information. You acknowledge and agree that this license includes a right for us to make such Animal Information available to other companies, organizations, or individuals with whom we have relationships, and to use such Animal Information in connection with the provision of the Services. Genetic samples that you submit to us become our property when submitted and cannot be returned.</p>
    
    <h1 class="font-medium">User Content License</h3>
    <p>You hereby grant (and you represent and warrant that you have the right to grant) to the Company an irrevocable, nonexclusive, royalty-free, and fully paid, worldwide license to reproduce, distribute, publicly display and perform, prepare derivative works of, incorporate into other works, and otherwise use and exploit your User Content, and to grant sublicenses of the foregoing rights, solely for the purposes of including your User Content in the Site. You hereby irrevocably waive (and agree to cause to be waived) any claims and assertions of moral rights or attribution with respect to your User Content. Notwithstanding the foregoing, any genetic sample provided by you to us shall become our property, as more fully described in Section 7 (Waiver of Property Rights).</p>
    
    <h1 class="font-medium">Acceptable Use Policy</h3>
    <p>The following terms constitute our “Acceptable Use Policy”:</p>
    <ul>
        <li>You agree not to use the Site to collect, upload, transmit, display, or distribute any User Content (i) that violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right, (ii) that is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another’s privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive, promotes racism, bigotry, hatred, or physical harm of any kind against any group, individual, or animal or is otherwise objectionable, (iii) that is harmful to minors in any way, or (iv) that is in violation of any law, regulation, or obligations or restrictions imposed by any third party.</li>
        <li>In addition, you agree not to: (i) upload, transmit, or distribute to or through the Site any computer viruses, worms, or any software intended to damage or alter a computer system or data; (ii) send through the Site unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise; (iii) use the Site to harvest, collect, gather, or assemble information or data regarding other users, including e-mail addresses, without their consent; (iv) interfere with, disrupt, or create an undue burden on servers or networks connected to the Site, or violate the regulations, policies or procedures of such networks; (v) attempt to gain unauthorized access to the Site (or to other computer systems or networks connected to or used together with the Site), whether through password mining or any other means; (vi) harass or interfere with any other user’s use and enjoyment of the Site; or (vii) use software or automated agents or scripts to produce multiple accounts on the Site, or to generate automated searches, requests, or queries to (or to strip, scrape, or mine data from) the Site (provided, however, that we may conditionally grant to the operators of public search engines revocable permission to use spiders to copy materials from the Site for the sole purpose of and solely to the extent necessary for creating publicly available searchable indices of the materials, but not caches or archives of such materials).</li>
    </ul>
    
    <h1 class="font-medium">Enforcement</h3>
    <p>We reserve the right (but have no obligation) to review, refuse, and/or remove any User Content in our sole discretion, and to investigate and/or take appropriate action against you in our sole discretion if you violate the Acceptable Use Policy or any other provision of these Terms or otherwise create liability for us or any other person. Such action may include removing or modifying your User Content, terminating your Account in accordance with Section 15, and/or reporting you to law enforcement authorities.</p>
</div>

<div class="my-2">
    <h2 class="font-bold">Limitations on the Tests and Results; No Refunds</h2>
    <p>We may not be able to process a genetic sample you submitted if there is not a sufficient volume of genetic material available from the sample or the results from processing do not meet our standards for accuracy. Even when samples are processed and meet our standards for accuracy, some or all of the data generated during the testing process may not be able to be accurately interpreted or may be incorrect (collectively, the “Errors”). As this possibility of Errors is known in advance, you agree that you are not entitled to a refund and you also agree not to pursue a refund when Errors occur or when you are otherwise unhappy or dissatisfied with the Services or the test results. You will pay for the Services in advance, and you are not entitled to a refund after the payment is made.</p>
</div>

<div class="my-2">
    <h2 class="font-bold">No Reliance</h2>
    <p>You acknowledge and understand that:</p>
    <ul>
        <li>The test results may not be what you expect, and may be perceived by you as unanticipated, unwelcome, negative or wrong;</li>
        <li>The test results may contain Errors; and</li>
        <li>Genes are only one factor that impacts the behavior or characteristics of an animal or that causes disease in animals.</li>
    </ul>
    <p>You agree that the Services are provided on an “AS-IS” basis and are based on the state of our research, testing procedures and testing equipment at the time the Services are rendered. All Services and test results are for information only and you agree that you will not rely on the test results for making decisions relating to the health, care, treatment, breeding, euthanasia, sale of the animal or for any other decisions relating to the animal. Prior to making any decisions relating to an animal as a result of the test results, you agree to obtain a separate and independent genetic analysis of the animal.</p>
</div>

<div class="my-2">
    <h2 class="font-bold">Waiver of Property Rights</h2>
    <p>Once a genetic sample is received by us, it becomes our property, and you waive any and all rights in or to the sample. You further understand that by providing the Animal Information to us, or having the animal’s genetic material tested, you acquire no rights in any research or commercial products that may be offered or developed by us, our affiliates or our collaborating partners. You specifically understand that you will not receive compensation for any research or commercial products that include or result from the Animal Information.</p>
</div>

<div class="my-2">
    <h2 class="font-bold">Use of Subcontractors</h2>
    <p>You acknowledge and agree that we may from time-to-time use subcontractors and sublicensees for the provision of the Services. You agree that the Personal Information and the Animal Information may be provided by the Company to these subcontractors and sublicensees.</p>
</div>

<div class="my-2">
    <h2 class="font-bold">Customer Representations, Warranties, Covenants and Agreements</h2>
    <p>You represent, warrant, covenant and agree, on behalf of yourself, your family, your successors and affiliates, and all owners of the animal(s), to the Company and its affiliates as follows:</p>
    <ul>
        <li>If you are an individual, you are at least 18 years of age and have full capacity to enter into these Terms; if you are an entity, you are duly organized, validly existing and good standing under the laws of the state, territory, province or country of its organization, and have full power and authority to enter into these Terms.</li>
        <li>You either own the animal for which the Services are being rendered, or you have legal authority to submit the Animal Information to the Company.</li>
        <li>You understand that any information or test results received from us are not designed to independently diagnose, prevent or treat any condition the animal may have. You understand that the Company’s Services are intended for research, informational and educational purposes only, and that while the test results obtained from the Company might point to a particular animal disease, defect or trait, it should always be confirmed by additional independent testing and evaluation.</li>
        <li>If you are located outside the United States, then you confirm that entering into these Terms, providing the Personal Information and providing the Animal Information is not subject to any export ban or restriction in the country or countries where you reside (if an individual) or does business (if an entity). Your entering into and performing your duties under these Terms does not violate any law or regulation which may apply to you.</li>
    </ul>
</div>

<div class="my-2">
    <h2 class="font-bold">Indemnification</h2>
    <p>You agree to fully indemnify, hold harmless and defend Etalon and its directors, officers, employees, agents, contractors, partners, stockholders, subsidiaries, affiliates, licensees, successors, and assigns (collectively, “Indemnified Parties”) from and against all claims, demands, actions, suits, damages, liabilities, losses, settlements, judgments, fees, fines, costs and expenses (including but not limited to reasonable attorneys’ fees and costs), whether or not involving a third party claim, which arise or relate to:</p>
    <ul>
        <li>Animal Information (including any samples) or Personal Information you provide, submit, post or transmit to the Company through the internet or otherwise;</li>
        <li>Any breach of any representation or warranty made by you in these Terms;</li>
        <li>Any breach or violation by you of any covenant or obligation or duty;</li>
        <li>Your use of the Services and the test results;</li>
        <li>Your or any of your affiliates or agents’ violation of these Terms; or</li>
        <li>Your violation of any rights of another person or entity, in each case whether or not caused by the negligence (including gross negligence) of Etalon or any other Indemnified Party and whether or not the relevant claim has merit.</li>
    </ul>
</div>

<div class="my-2">
    <h2 class="font-bold">Third Party Links and Ads; Other Users</h2>
    <h1 class="font-medium">Third Party Links & Ads</h3>
    <p>The Site may contain links to third-party websites and services, and/or display advertisements for third parties (collectively, “Third-Party Links & Ads”). Such Third-Party Links & Ads are not under the control of the Company, and Company is not responsible for any Third-Party Links & Ads. The Company provides access to these Third-Party Links & Ads only as a convenience to you, and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Links & Ads. You use all Third-Party Links & Ads at your own risk, and should apply a suitable level of caution and discretion in doing so. When you click on any of the Third-Party Links & Ads, the applicable third party’s terms and policies apply, including the third party’s privacy and data gathering practices. You should make whatever investigation you feel necessary or appropriate before proceeding with any transaction in connection with such Third-Party Links & Ads.</p>

    <h1 class="font-medium">Other Users</h3>
    <p>Each Site user is solely responsible for any and all of its own User Content. Since we do not control User Content, you acknowledge and agree that we are not responsible for any User Content, whether provided by you or by others. We make no guarantees regarding the accuracy, currency, suitability, appropriateness, or quality of any User Content. Your interactions with other Site users are solely between you and such users. You agree that Company will not be responsible for any loss or damage incurred as the result of any such interactions. If there is a dispute between you and any Site user, we are under no obligation to become involved.</p>
    
    <h1 class="font-medium">Release</h3>
    <p>You hereby release and forever discharge the Company (and our officers, employees, agents, successors, and assigns) from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature (including personal injuries, death, and property damage), that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Site (including any interactions with, or act or omission of, other Site users or any Third-Party Links & Ads), the User Content and the Services. IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.”</p>
</div>

<div class="my-2">
    <h2 class="font-bold">Disclaimer of Warranties</h2>
    <p class="important">YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT:</p>
    <ul>
        <li>YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK; THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS AND MAY CONTAIN ERRORS OR MAY BE INCOMPLETE; AND WE (AND OUR SUBCONTRACTORS AND SUPPLIERS) EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</li>
        <li>WE (AND OUR SUBCONTRACTORS AND SUPPLIERS) MAKE NO WARRANTY THAT: (A) THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS; (B) THE SERVICES WILL BE TIMELY, UNINTERRUPTED, UNFAILINGLY SECURE, OR ERROR-FREE; (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL BE ACCURATE, RELIABLE, COMPLETE OR LEGAL; (D) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICES WILL MEET YOUR EXPECTATIONS AND (E) ANY ERRORS IN THE TESTING PROCEDURE OR IN OUR TECHNOLOGY WILL BE CORRECTED.</li>
        <li>ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF OUR SITE IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE USE OF THE OUR WEBSITE.</li>
        <li>NO DATA, TEST RESULTS OR OTHER INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS.</li>
        <li>YOU SHOULD ALWAYS USE CAUTION WHEN GIVING OUT ANY PERSONALLY IDENTIFYING INFORMATION ABOUT YOURSELF OR THOSE FOR WHOM YOU HAVE LEGAL AUTHORITY. WE DO NOT CONTROL OR ENDORSE ANY ACTIONS RESULTING FROM YOUR USE OF THE SERVICES OR THE TEST RESULTS, AND, THEREFORE, WE SPECIFICALLY DISCLAIM ANY LIABILITY WITH REGARD TO ANY ACTIONS RESULTING FROM YOUR USE OF THE SERVICES OR THE TEST RESULTS.</li>
    </ul>
</div>

<div class="my-2">
    <h2 class="font-bold">Limitations on Liability</h2>
    <p class="important">WITHIN THE LIMITS ALLOWED BY APPLICABLE LAWS, YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT WE SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM:</p>
    <ul>
        <li>THE USE OR THE INABILITY TO USE THE SERVICES;</li>
        <li>ANY ACTION YOU TAKE OR REFRAIN FROM TAKING BASED ON THE INFORMATION YOU RECEIVE IN, THROUGH OR FROM THE SERVICES,</li>
        <li>YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE AND CONFIDENTIAL,</li>
        <li>UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR INFORMATION, TRANSMISSIONS OR DATA;</li>
        <li>THE IMPROPER AUTHORIZATION FOR THE SERVICES BY SOMEONE CLAIMING SUCH AUTHORITY; OR</li>
        <li>STATEMENTS OR CONDUCT OF ANY THIRD PARTY RELATING TO THE SERVICES.</li>
    </ul>
    <p>OUR MAXIMUM LIABILITY TO YOU AND YOUR AFFILIATES SHALL IN NO EVENT EXCEED THE FEES PAID BY YOU TO US FOR THE SERVICES. YOU AGREE THAT OUR SUBCONTRACTORS AND SUPPLIERS WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THESE TERMS.</p>
</div>

<div class="my-2">
    <h2 class="font-bold">Dispute Resolution</h2>
    <h1 class="font-medium">Initial Dispute Resolution</h3>
    <p>We are available by email at info@etalondx.com to address any concerns you may have regarding your use of the Services. Most concerns may be quickly resolved in this manner. You agree to use your best efforts to settle any dispute, claim, question, or disagreement directly through consultation and good faith negotiations which shall be a precondition to either party initiating arbitration.</p>

    <h1 class="font-medium">Binding Arbitration</h3>
    <p>If we do not reach an agreed upon solution within a period of thirty (30) days from the time informal dispute resolution is pursued pursuant to the Initial Dispute Resolution section above, then either you or we may initiate binding arbitration. Any controversy, claim or dispute whether in contract, tort, statute or otherwise, arising out of or relating to these Terms (including but not limited to the Privacy Statement), the parties’ relationship with each other, and/or your use of the Services, shall be settled in arbitration in San Francisco, California in accordance with the Arbitration Rules of the American Arbitration Association ("AAA"), and if permitted by the AAA, by one arbitrator. You hereby submit to the exclusive jurisdiction of the AAA in San Francisco, California. The award of the arbitrator(s) shall be final and binding and is your sole and exclusive remedy regarding any controversy or claim hereunder except that nothing contained in these Terms shall prohibit us from seeking injunctive relief or equitable remedies in a court of competent jurisdiction in San Francisco, California of pursuing other equitable remedies. The party initiating the arbitration shall pay for all costs and fees of the arbitration, including the fees and costs of the AAA and the fees of the arbitrator(s). Judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction.</p>

    <h1 class="font-medium">30-Day Right to Opt Out of Arbitration</h3>
    <p>You have the right to opt out of the binding arbitration provision contained in Section 14(b) above by sending a timely written notice of your decision to opt out to the email address info@etalondx.com within 30 days after the first to occur of your accessing this Site, creating an Account, or otherwise becoming subject to these Terms. Your notice must include your name and address and a clear statement that you want to opt out of binding arbitration. If you opt out of binding arbitration, then all other parts of these Terms will continue to apply to you. Opting out of binding arbitration has no effect on any other arbitration agreements that you may currently have with us, or may enter into in the future with us. If you opt out of arbitration, you agree that all disputes, actions and proceedings shall be resolved exclusively by a state or federal court located in San Francisco, California, and you further irrevocably consent to the exclusive jurisdiction and venue of the state and federal courts located in San Francisco, California.</p>

    <h1 class="font-medium">Class Action and Class Arbitration Waiver</h3>
    <p>You agree that any arbitration or other proceeding shall be conducted in your individual capacity only and not as a class action, and you expressly waive your rights to file a class action or seek relief on a class basis.</p>

    <h1 class="font-medium">Term for Cause of Action</h3>
    <p>You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Services or these Terms must be filed within one (1) year after such claim or cause of action arose or be forever barred.</p>

    <h1 class="font-medium">Waiver of Jury Trial</h3>
    <p class="important">YOU HEREBY WAIVE YOUR RIGHTS TO A TRIAL BY JURY OF ANY CLAIM OR CAUSE OF ACTION BASED UPON THE SERVICES OR THESE TERMS, INCLUDING WITHOUT LIMITATION CONTRACT CLAIMS, TORT CLAIMS, BREACH OF DUTY CLAIMS AND ALL OTHER COMMON LAW OR STATUTORY CLAIMS.</p>

    <h1 class="font-medium">Attorneys' Fees</h3>
    <p>If any arbitration or other proceeding is brought for the enforcement of these Terms, or because of an alleged dispute or breach in connection with these Terms, the successful or prevailing party shall be entitled to recover reasonable attorneys' fees and other costs incurred in that action or proceeding, in addition to any other relief to which it may be entitled.</p>
</div>

<div class="my-2">
    <h2 class="font-bold">Term and Termination</h2>
    <p>Subject to this Section, these Terms will remain in full force and effect while you use the Site. We may suspend or terminate your rights to use the Site (including your Account) at any time for any reason or no reason at all at our sole discretion, including but not limited to for any use of the Site in violation of these Terms. Upon termination of your rights under these Terms, your Account and right to access and use the Site will terminate immediately. You understand that any termination of your Account may involve deletion of your User Content associated with your Account from our live databases. The Company will not have any liability whatsoever to you for any termination of your rights under these Terms, including but not limited to for termination of your Account or deletion of your User Content. Even after your rights under these Terms are terminated, the following provisions of these Terms will remain in effect: Sections 3 through Section 16.</p>
</div>

<div class="my-2">
    <h2 class="font-bold">Miscellaneous</h2>
    <h1 class="font-medium">English Language; US Dollars</h3>
    <p>All communications, correspondence, documents, meetings, discussions, conversations, notices and proceedings and all other matters in connection with the Services and the Terms will be exclusively in English. All prices are quoted in US Dollars and all amounts paid by you shall be in US Dollars. You shall bear all fees and expenses incurred by you related to the method of delivery of your monies to us.</p>

    <h1 class="font-medium">Notices; Electronic Communications</h3>
    <p>Notices or other communications provided for by these Terms shall be given in writing. The communications between you and Company use electronic means, whether you use the Site or send us emails, or whether Company posts notices on the Site or communicates with you via email. For contractual purposes, you (a) consent to receive communications from Company in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Company provides to you electronically satisfy any legal requirement that such communications would satisfy if it were in a hardcopy writing.</p>

    <h1 class="font-medium">Construction; Severability</h3>
    <p>The parties agree that the rule of construction that a contract be construed against the drafter shall not be applied in interpreting these Terms. If any paragraph, term or provision of these Terms shall be held or determined to be unenforceable, the balance of these Terms shall nevertheless continue in full force and effect, unaffected by such holding or determination. In addition, in any such event, the parties agree that it is their intention and agreement that any such section, paragraph, term or provision which is held or determined to be unenforceable, as written, shall nonetheless be in force and binding to the fullest extent permitted by law as though such section, term or provision had been written in such a manner and to such an extent as to be enforceable under the circumstances.</p>

    <h1 class="font-medium">Entire Agreement; Amendment</h3>
    <p>These Terms embody the entire agreement of the parties hereto and supersedes all prior agreements and understandings between the parties with respect thereto. The provisions of these Terms may be waived, altered, amended, modified, or repealed, in whole or in part, only on the written consent of both you and us.</p>

    <h1 class="font-medium">No Waiver</h3>
    <p>The waiver by either party of a breach of any provision of these Terms shall not operate or be construed as a waiver of any subsequent breach by the other party.</p>

    <h1 class="font-medium">Assignment</h3>
    <p>You may not assign or delegate any rights or obligations under these Terms, and any purported assignment and/or delegation shall be void and ineffective. We may freely assign or delegate all rights and obligations under these Terms, fully or partially without notice to you. For example, if we are acquired by another company, we may assign all of our rights and delegate all of our duties to the company that acquired us.</p>

    <h1 class="font-medium">Governing Law</h3>
    <p>These Terms, and all claims and disputes arising out of or related to these Terms, whether in contract, tort, statute or otherwise, shall be governed by the substantive and procedural laws of the State of California without regard to principles of conflict of laws.</p>

    <h1 class="font-medium">Export</h3>
    <p>The Site may be subject to U.S. export control laws and may be subject to export or import regulations in other countries. You agree not to export, reexport, or transfer, directly or indirectly, any U.S. technical data acquired from Company, or any products utilizing such data, in violation of the United States export laws or regulations.</p>

    <h1 class="font-medium">Copyright/Trademark Information</h3>
    <p>Copyright © 2023 Etalon, Inc. All rights reserved. All trademarks, logos and service marks (“Marks”) displayed on the Site are our property or the property of other third parties. You are not permitted to use these Marks without our prior written consent or the consent of such third party which may own the Marks.</p>

    <h1 class="font-medium">Changes to These Terms</h3>
    <p>We may make changes to the Terms in the future. If we make any substantial changes to these Terms, we may notify you by sending you an e-mail to the last e-mail address you provided to us (if any), and/or by prominently posting notice of the changes on our Site. You are responsible for providing us with your most current e-mail address. In the event that the last e-mail address that you have provided us is not valid, or for any reason is not capable of delivering to you the notice described above, our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice. Continued use of our Site following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.</p>
</div>

<div class="my-2">
    <h1 class="font-bold text-lg">Etalon Privacy Statement</h1>
    <h1 class="font-medium">Last Updated: June 28, 2023</h3>

    <h2 class="font-bold">What You Should Know About Privacy At Etalon</h2>
    <p>This Privacy Statement applies to:</p>
    <ul>
        <li>The website <a href="http://www.etalondx.com">www.etalondx.com</a> (the “Site”) owned and operated by Etalon, a California corporation (“Etalon”) including any other websites, pages, features, or content we own or operate, and</li>
        <li>Any services that Etalon provides to you, including but not limited to animal genetic testing (the “Services”).</li>
    </ul>
</div>

<div class="my-2">
    <h2 class="font-bold">The Information Etalon Collects</h2>
    <p>We try not to speak in legalese, but there are some useful definitions we use to describe data we collect in providing the Services to you.</p>
    <p>When we say Personal Information, we use this as a general term to refer to the different data categories we describe in this section that either personally identify or are about you or your animal. Your Personal Information can be either:</p>
    <ul>
        <li><strong>Individual-level Information</strong>: information about you and your animal that you upload to our site.</li>
        <li><strong>De-identified Information</strong>: information that has been stripped of identifying data, such as name and contact information, so that an individual cannot reasonably be identified or, in the case of an animal information about the animal that has been stripped of the animal’s name.</li>
    </ul>
    <p>Here are the types of Information we collect:</p>
    <ul>
        <li><strong>Registration Information</strong>: information you provide during account registration or when purchasing the Services, such as a name, user ID, password, date of birth, billing address, shipping address, payment information (e.g., credit card), account authentication information, or contact information (e.g., email, phone number) (the “Personal Information”).</li>
        <li><strong>Animal Information</strong>: information regarding your animal, including breed, gender, measurements, lineage, disease conditions, health-related information and other identifying information, including the results of any animal genetic testing (collectively, the “Animal Information”).</li>
        <li><strong>Sample Information</strong>: information regarding any animal DNA sample, such as a hair, that you submit for processing to be analyzed to provide you with genetic information or other information or data provided through our Services.</li>
        <li><strong>Self-Reported Information</strong>: information you provide to Etalon including your Personal Information, the Animal Information and the Sample Information.</li>
        <li><strong>User Content</strong>: information, data, text, software, music, audio, photographs, graphics, video, messages, or other materials, other than Personal Information, generated by users of Etalon Services and transmitted, whether publicly or privately, to or through Etalon and/or its website. For example, User Content includes messages you send to other Etalon website users through our Services.</li>
        <li><strong>Web-Behavior Information</strong>: information on how you use our Services or about the way your devices use our Services is collected through log files, cookies, web beacons, and similar technologies (e.g., device information, device identifiers, IP address, browser type, location, domains, page views).</li>
    </ul>
    <p><strong>Aggregate Information</strong> is different from Personal Information</p>
    <p>Aggregate Information is not Personal Information because Aggregate Information does not contain information about, nor can it reasonably be linked to, a specific individual. Aggregate Information is information about a group of people, such as an analysis or evaluation of a group. Aggregate Information describes the group as a whole in such a way that no specific individual may be reasonably identified. For example, the number of Etalon customers with certain breed of animal is Aggregate Information.</p>
</div>

<div class="my-2">
    <h2 class="font-bold">How We Collect Information</h2>
    <p>We collect information in the following ways:</p>
    <ul>
        <li><strong>You</strong>: We collect information you provide to us when you request or purchase Services or information from us, register with us, participate in activities on our sites, features, and applications, contact our customer support, or otherwise interact with us. You may provide information in a variety of ways, including by typing or using voice commands.</li>
        <li><strong>Service Providers</strong>: We may collect information through service providers who use a variety of technologies and tools, such as cookies, analytics tools, software development kits, application program interfaces, web beacons, pixels, and tags when you visit, use or interact with our Site or Services.</li>
        <li><strong>Other Third Parties</strong>: We may receive information about you from other users, individuals, or other third parties. For example, if someone gifts you an animal DNA testing kit, invites you to view their animal DNA test results, or otherwise refers you to Etalon, we may collect information about you.</li>
        <li><strong>Etalon</strong>: We may infer new information from other data we collect, including using automated means to generate information about your likely preferences or other characteristics. For example, we may infer your location (such as city, state, and country) based on your IP address.</li>
    </ul>
</div>

<div class="my-2">
    <h2 class="font-bold">How We Use Your Information</h2>
    <p>Now that we’ve covered the types of information we collect and how we collect it, let’s review how we may use it. As a reminder, we will not use your Personal Information for personalized or targeted marketing and/or advertising without your explicit consent.</p>
    <p>We use your information to:</p>
    <ul>
        <li>Provide our Services, including to develop, operate, improve, maintain, and safeguard our Services, including developing new product tools and features.</li>
        <li>Populate our animal databases with Animal Information.</li>
        <li>Analyze and measure trends and usage of the Services.</li>
        <li>Communicate with you, including customer support, or to share information about our Services or other offers or information we think may be relevant to you.</li>
        <li>Personalize, contextualize and market our Services to you.</li>
        <li>Enhance the safety, integrity, and security of our Services, including prevention of fraud and other unauthorized or illegal activities on our Services.</li>
        <li>Enforce, investigate, and report conduct violating our Terms of Service or other policies.</li>
        <li>Conduct surveys or polls, and obtain testimonials or stories about you or your animal(s).</li>
        <li>Comply with our legal, licensing, and regulatory obligations.</li>
        <li>Conduct research regarding animal genetics using the Animal Information and the Sample Information.</li>
    </ul>
</div>

<div class="my-2">
    <h2 class="font-bold">Data Sharing</h2>
    <p>We appreciate the level of trust you put into us. Here’s how we do, and do not share your information.</p>
    <h1 class="font-medium">Who we share with:</h3>
    <ul>
        <li><strong>Service providers</strong>: Our service providers, contractors and licensees help us provide our Services and act on our behalf to get things done. We implement procedures and maintain contractual terms with each service provider, contractor and licensee to protect the confidentiality and security of your Personal Information, the Animal Information and the Sample Information. For example, some of the things we use service providers and contractors to help us with include order fulfillment and shipping; processing and analyzing the animal DNA samples; customer care support; cloud storage, IT, and security; marketing and analytics; and more.</li>
        <li><strong>Commonly owned entities, affiliates and change of ownership</strong>: If we are involved in a bankruptcy, merger, acquisition, reorganization, or sale of assets, your Personal Information, the Animal Information and the Sample Information may be accessed, sold or transferred as part of that transaction and this Privacy Statement will apply to your Personal Information, Animal Information and Sample Information as transferred to the new entity. We may also disclose Personal Information, Animal Information and Sample Information about you to our corporate affiliates to help operate our services and our affiliates’ services.</li>
        <li><strong>Third parties related to law, harm, and the public interest</strong>: Etalon will not provide information to law enforcement unless required by law to comply with a valid court order, subpoena, or search warrant. We require all law enforcement inquiries to follow a valid legal process, such as a court order or search warrant. If we are compelled to disclose your Personal Information to law enforcement, we will try our best to provide you with prior notice, unless we are prohibited from doing so under the law.</li>
    </ul>
    <p>Etalon will preserve and disclose any and all information if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to:</p>
    <ul>
        <li>Comply with legal or regulatory process (such as a judicial proceeding, court order, or government inquiry) or obligations that Etalon may owe pursuant to ethical and other professional rules, laws, and regulations;</li>
        <li>Enforce the Etalon Terms of Service and other policies;</li>
        <li>Respond to claims that any content violates the rights of third parties; or</li>
        <li>Protect the rights, property, or personal safety of Etalon, its employees, officers, directors, contractors or other personnel, and its users.</li>
    </ul>
    <p>Nothing in this Privacy Statement is intended to limit any legal defenses or objections that you may have to a third party’s, including a government’s, request to disclose your Personal Information.</p>
</div>

<div class="my-2">
    <h1 class="font-medium">Who we DO NOT share with:</h3>
    <ul>
        <li>Etalon will not voluntarily share your Personal Information, Animal Information or Sample Information with:</li>
        <ul>
            <li>Public databases; or</li>
            <li>Law enforcement, absent a valid court order, subpoena, or search warrant.</li>
        </ul>
    </ul>
</div>

<div class="my-2">
    <h2 class="font-bold">Your Privacy Settings And Controls</h2>
    <p>It’s your data, and we make it easy to make decisions and certain choices about it. We do not make choices on your behalf for the privacy settings described below. Below are the types of controls you have in your Account Settings and we’ve listed what it means to opt-out or to opt-in:</p>
    <h1 class="font-medium">Sharing Animal Information features</h3>
    <ul>
        <li><strong>Opt-out</strong>: No, I do not want to share the Animal Information with other users via features available on the Site.</li>
        <li><strong>Opt-in</strong>: Yes, I want to be able to share the Animal Information so I can participate in the “Build a Dog” and other programs.</li>
    </ul>
    <h1 class="font-medium">Communications preferences</h3>
    <ul>
        <li><strong>Opt-out</strong>: Please don’t contact me for promotional purposes. In addition to changing your preferences via Account Settings or your device, you can also click the “unsubscribe” button at the bottom of promotional email communications.</li>
        <li><strong>Opt-in</strong>: Yes, you can contact me (such as through email, in-product notifications, or push notifications) for product or promotional purposes.</li>
    </ul>
    <p>You can also:</p>
    <ul>
        <li><strong>Access Your Personal Information</strong>: You can access your Personal Information. Please note, if you lose access to your Etalon Account, we require that you submit additional information to verify your identity before providing access or otherwise releasing Personal Information to you.</li>
        <li><strong>Correct Information</strong>: You can correct your Registration Information and modify Self-Reported Information entered into surveys.</li>
        <li><strong>Delete your Account</strong>: You can delete your Etalon account within your Account Settings at any time. Keep in mind this process cannot be canceled, undone, withdrawn, or reversed, and your account deletion is subject to retention requirements.</li>
    </ul>
</div>

<div class="my-2">
    <h2 class="font-bold">Other Things To Know About Privacy</h2>
    <h1 class="font-medium">Security Measures</h3>
    <p>We implement physical, technical, and administrative measures aimed at preventing unauthorized access to or disclosure of your Personal Information, the Animal Information and the Sample Information. Our team regularly reviews and improves our security practices to help ensure the integrity of our systems and your Personal Information.</p>
    <p>Please recognize that protecting your Personal Information is also your responsibility. Be mindful of keeping your password and other authentication information safe from third parties, and immediately notify Etalon of any unauthorized use of your login credentials. Your password is not visible to Etalon staff, and we encourage you not to share your password with Etalon or any third parties. Etalon cannot secure Personal Information that you release on your own or that you request us to release.</p>

    <h1 class="font-medium">Third Party Content and Integrations</h3>
    <p>Our Services may contain third party content, integrations or links to third party websites operated by organizations not affiliated with Etalon. Through these integrations, you may be providing information to the third party as well as to Etalon. Since we can only control our own Services, we are not responsible for how those third parties collect or use your information so please review the privacy policies of every third-party service that you visit or use, including those third parties you interact with through our Services.</p>

    <h1 class="font-medium">Federal, State, and Region-Specific Information</h3>
    <p>You may have specific privacy rights in your state or region. For example, in the United States, residents of California and other states may have specific privacy rights, and residents of the European Economic Area (EEA), the UK, Switzerland and other jurisdictions may have specific privacy rights.</p>

    <h1 class="font-medium">Retention of Personal Information</h3>
    <p>We retain Personal Information for as long as necessary to provide the Services and fulfill the transactions you have requested, comply with our legal obligations, resolve disputes, enforce our agreements, and other legitimate and lawful business purposes. Because these needs can vary for different data types in the context of different services, actual retention periods can vary significantly based on criteria such as user expectations or consent, the sensitivity of the data, the availability of automated controls that enable users to delete data, and our legal or contractual obligations.</p>
    <p>If you choose to delete your account, Etalon will also retain limited information related to your account and data deletion request, including but not limited to, your email address, account deletion request identifier, communications related to inquiries or complaints and legal agreements for a limited period of time as required by law, contractual obligations, and/or as necessary for the establishment, exercise or defense of legal claims and for audit and compliance purposes.</p>
</div>

<div class="my-2">
    <h2 class="font-bold">Changes to this Privacy Statement</h2>
    <p>We may make changes to this Privacy Statement from time to time. We’ll let you know about those changes here or by reaching out to you via email or some other contact method, such as through in-app notification, or on another website page or feature.</p>
</div>

<div class="my-2">
    <h2 class="font-bold">Contact Information</h2>
    <p>If you have questions about this Privacy Statement, or have a complaint or inquiry, please email us at <a href="mailto:info@alphadogdna.com">info@alphadogdna.com</a>.</p>
</div>
`

export function TosAgreementDialog({ onClickAcceptAgreement }: TosAgreementDialogProps) {
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [name, setName] = useState('')

  return (
    <Dialog modal open>
      <DialogContent className="bg-gradient-to-b from-doggradient-300 to-doggradient-100 shadow-md border-2 border-white backdrop-blur-[50px] rounded-3xl bg-[unset] max-h-screen h-full min-h-0">
        <DialogHeader>
          <DialogTitle className="text-2xl">
            <div className="flex gap-2 items-center">
              <span>Privacy & Terms of Service</span>
            </div>
          </DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-2 h-full min-h-0">
          <div className="flex-1 overflow-y-auto w-full my-4 min-h-0">
            <div
              dangerouslySetInnerHTML={{
                __html: TOS_TEXT,
              }}
            />
          </div>

          <hr className="border border-slate-200 w-full my-2" />

          <div className="flex- min-h-0">
            <div className="mb-1 flex gap-1 items-center w-full">
              <Checkbox
                onCheckedChange={(checkedState) => {
                  setAcceptedTerms(!acceptedTerms)
                }}
                checked={acceptedTerms}
              />
              <span>I have read and agree to the terms and conditions</span>
            </div>

            <div className="mb-1 w-full">
              <Input onChange={(e) => setName(e.target.value)} type="text" value={name} placeholder="Type your name" />
            </div>

            <div className="mb-1 flex justify-between w-full text-sm font-bold">
              <div>Current Date</div>

              <div>{new Date().toLocaleDateString()}</div>
            </div>

            <div className={cn(`flex justify-end gap-6 w-full`, !acceptedTerms ? 'hidden' : '')}>
              <DogButton
                onClickFn={() => {
                  onClickAcceptAgreement({
                    name,
                  })
                }}
              >
                Accept
              </DogButton>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
