import React, { MouseEventHandler } from 'react'
import { Button } from '@edx/react-common'

interface DogButtonProps {
  onClickFn?: MouseEventHandler | undefined
  classNames?: string
  variant?: 'brand' | 'default' | 'destructive' | 'outline' | 'secondary' | 'ghost' | 'link' | null | undefined
  children: any
  disabled?: boolean
  type?: "submit" | "reset" | "button" | undefined;
}

export function DogButton({ onClickFn, children, classNames, variant, disabled, type }: DogButtonProps) {
  return (
    <Button type={type} disabled={disabled} className={`border-[1px] text-edxred-500 border-edxred-500 w-full rounded-[30px] h-12 !font-semibold !text-xl hover:bg-edxred-500 hover:text-white hover:border-0 ${classNames}`} onClick={onClickFn} variant={variant || 'outline'}>
      {children}
    </Button>
  )
}
