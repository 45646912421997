import {
  Button,
  Label,
  RadioGroup,
  RadioGroupItem,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@edx/react-common'
import { Link, useSearchParams } from 'react-router-dom'
import { FaStar } from 'react-icons/fa6'
import { useEffect, useState } from 'react';
import { IStripeProduct } from '../state/dogs';
import { getProducts } from '../apis/products';
import { loadStripe } from '@stripe/stripe-js';
import { Environment } from '../../config/environment';
import { createCheckoutSession } from '../apis/session';
import Image from '../components/Image';
import DogPlaceholderImage from '../../public/svgs/dog-placeholder.svg';

const stripePromise = loadStripe(String(Environment.STRIPE_PUBLISHABLE_KEY));

export function TestProductScreen() {
  const [currentProduct, setCurrentProduct] = useState<IStripeProduct | null>(null);
  const [products, setProducts] = useState<IStripeProduct[]>([]);
  const [quantity, setQuantity] = useState(1);
  const [searchParams] = useSearchParams();
  
  useEffect(() => {
    getProducts().then(prods => {
      setProducts(prods.data as IStripeProduct[]);
    });
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      const selectedProd = products.find((item: any) => item.id === searchParams?.get('id')) || products[0];
      setCurrentProduct(selectedProd);
    }
  }, [products, searchParams]);

  const onCheckout = async () => {
    if (currentProduct) {
      const price = currentProduct.default_price || (Number(currentProduct.prices?.length) > 0 ? String(currentProduct.prices![0].id) : '');
      const stripe = await stripePromise;
      const res = await createCheckoutSession([{ price, quantity }]);
      const { sessionId } = res.data;
      await stripe?.redirectToCheckout({ sessionId });
    }
  };

  const onQuantityChange = (qty: string) => {
    setQuantity(Number(qty));
  }

  return (
    <div className="flex flex-col space-y-12">
      <section className="grid md:grid-cols-2 gap-6 lg:gap-12 items-start max-w-6xl px-4 mx-auto">
        <div>
          <Image
            src={currentProduct?.images[0]}
            alt="Product Image"
            width={300}
            height={200}
            className="object-cover border border-gray-200 w-full rounded-lg overflow-hidden dark:border-gray-800 h-[320px]"
            placeholder={DogPlaceholderImage}
          />
        </div>
        <div className="grid gap-6 md:gap-10">
          <div>
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold tracking-tight">{currentProduct?.name}</h1>
            <p className="text-gray-500 dark:text-gray-400 mt-2 text-lg">
              {currentProduct?.description}
            </p>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-0.5">
              <FaStar className="w-5 h-5 fill-primary" />
              <FaStar className="w-5 h-5 fill-primary" />
              <FaStar className="w-5 h-5 fill-primary" />
              <FaStar className="w-5 h-5 fill-muted stroke-muted-foreground" />
              <FaStar className="w-5 h-5 fill-muted stroke-muted-foreground" />
            </div>
            <div className="text-4xl font-bold">${currentProduct?.prices && currentProduct?.prices?.length > 0 ? currentProduct?.prices[0].unit_amount / 100 : 0}</div>
          </div>

          <form className="grid gap-4 md:gap-6">
            <div className="grid gap-2">
              <Label htmlFor="color" className="text-base">
                Color
              </Label>
              <RadioGroup id="color" defaultValue="black" className="flex items-center gap-2">
                <Label
                  htmlFor="color-black"
                  className="border cursor-pointer rounded-md p-2 flex items-center gap-2 [&:has(:checked)]:bg-gray-100 dark:[&:has(:checked)]:bg-gray-800"
                >
                  <RadioGroupItem id="color-black" value="black" />
                  Black
                </Label>
                <Label
                  htmlFor="color-white"
                  className="border cursor-pointer rounded-md p-2 flex items-center gap-2 [&:has(:checked)]:bg-gray-100 dark:[&:has(:checked)]:bg-gray-800"
                >
                  <RadioGroupItem id="color-white" value="white" />
                  White
                </Label>
                <Label
                  htmlFor="color-blue"
                  className="border cursor-pointer rounded-md p-2 flex items-center gap-2 [&:has(:checked)]:bg-gray-100 dark:[&:has(:checked)]:bg-gray-800"
                >
                  <RadioGroupItem id="color-blue" value="blue" />
                  Blue
                </Label>
              </RadioGroup>
            </div>
            <div className="grid gap-2">
              <Label htmlFor="size" className="text-base">
                Size
              </Label>
              <RadioGroup id="size" defaultValue="m" className="flex items-center gap-2">
                <Label
                  htmlFor="size-xs"
                  className="border cursor-pointer rounded-md p-2 flex items-center gap-2 [&:has(:checked)]:bg-gray-100 dark:[&:has(:checked)]:bg-gray-800"
                >
                  <RadioGroupItem id="size-xs" value="xs" />
                  XS
                </Label>
                <Label
                  htmlFor="size-s"
                  className="border cursor-pointer rounded-md p-2 flex items-center gap-2 [&:has(:checked)]:bg-gray-100 dark:[&:has(:checked)]:bg-gray-800"
                >
                  <RadioGroupItem id="size-s" value="s" />S
                </Label>
                <Label
                  htmlFor="size-m"
                  className="border cursor-pointer rounded-md p-2 flex items-center gap-2 [&:has(:checked)]:bg-gray-100 dark:[&:has(:checked)]:bg-gray-800"
                >
                  <RadioGroupItem id="size-m" value="m" />M
                </Label>
                <Label
                  htmlFor="size-l"
                  className="border cursor-pointer rounded-md p-2 flex items-center gap-2 [&:has(:checked)]:bg-gray-100 dark:[&:has(:checked)]:bg-gray-800"
                >
                  <RadioGroupItem id="size-l" value="l" />L
                </Label>
                <Label
                  htmlFor="size-xl"
                  className="border cursor-pointer rounded-md p-2 flex items-center gap-2 [&:has(:checked)]:bg-gray-100 dark:[&:has(:checked)]:bg-gray-800"
                >
                  <RadioGroupItem id="size-xl" value="xl" />
                  XL
                </Label>
              </RadioGroup>
            </div>
            <div className="grid gap-2">
              <Label htmlFor="quantity" className="text-base">
                Quantity
              </Label>
              <Select defaultValue="1" onValueChange={onQuantityChange}>
                <SelectTrigger className="w-24">
                  <SelectValue placeholder="Select" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="1">1</SelectItem>
                  <SelectItem value="2">2</SelectItem>
                  <SelectItem value="3">3</SelectItem>
                  <SelectItem value="4">4</SelectItem>
                  <SelectItem value="5">5</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <Button size="lg">Add to cart</Button>
            <Button size="lg" onClick={onCheckout} type='button'>Check out</Button>
          </form>
        </div>
      </section>

      <section className="w-full py-12 md:py-16 lg:py-20 bg-gray-100 dark:bg-gray-800">
        <div className="container grid gap-6 md:gap-8 px-4 md:px-6">
          <div className="flex flex-col md:flex-row items-start md:items-center gap-4 md:gap-8">
            <div className="grid gap-1">
              <h2 className="text-2xl font-bold tracking-tight">Related Products</h2>
              <p className="text-gray-500 dark:text-gray-400">You may also be interested in these products.</p>
            </div>
          </div>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 md:gap-8">
            {products.map(prod => (
              <div className="relative group overflow-hidden rounded-lg" key={prod.id}>
                <Link to={`/products/test?id=${prod.id}`} className="absolute inset-0 z-10">
                  <span className="sr-only">View</span>
                </Link>
                <img src={prod.images[0]} alt="Product 1" width={400} height={300} className="object-cover w-full h-60" />
                <div className="bg-white p-4 dark:bg-gray-950">
                  <h3 className="font-semibold text-lg md:text-xl">{prod.name}</h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {prod.description}
                  </p>
                  <h4 className="font-semibold text-base md:text-lg">${(prod.prices && prod.prices?.length > 0) ? prod.prices[0].unit_amount : 0}</h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}
