import React, { useEffect, useMemo } from 'react'
import { ScreenTitle } from '../../components/ScreenTitle'
import { useActivationScreenState } from './state.ts'
import { Input, LoadingSpinner, useWindowSize } from '@edx/react-common'
import { SelectDogDialog } from '../../components/SelectDogDialog'
import { ImSpinner10 } from 'react-icons/im'
import { DogButton } from '../../components/DogButton.tsx'
import { FaPlus } from 'react-icons/fa6'
import { ActivationCodesList } from './components/ActivationCodesList.tsx'
import Confetti from 'react-confetti'
import { useNavigate } from 'react-router-dom'

export function ActivationScreen() {
  const state = useActivationScreenState()
  const navigate = useNavigate()

  const { width, height } = useWindowSize()

  useEffect(() => {
    state.actions.setActivationCodeInputValue('')
  }, [])

  useEffect(() => {
    state.actions.loadDogs()

    return () => {
      state.actions.__reset__()
    }
  }, [])

  const availableDogsForAssignment = useMemo(() => {
    return state.dogs.filter(({ animalId }) => {
      return state.activationCodes.every(({ animalId }) => animalId !== animalId)
    })
  }, [state.activationCodes, state.dogs])

  if (state.submittingActivationCodes) {
    return (
      <div className="flex flex-col justify-center items-center h-full gap-12 relative">
        <ScreenTitle>Submitting Activation Code{state.activationCodes.length > 1 ? `'s` : ''}</ScreenTitle>

        <LoadingSpinner />

        <ActivationCodesList />
      </div>
    )
  }

  if (state.submitActivationCodesResult?.success) {
    return (
      <div className="flex flex-col justify-center items-center h-full gap-4">
        <ScreenTitle>Congratulations!</ScreenTitle>
        <p className="max-w-[764px] text-center">
          Your code(s) was/were successfully activated!
          <br /> Follow the instructions in the kit, and your dog(s) unique DNA will be on its way to our lab for
          processing!
        </p>

        <ActivationCodesList />

        <hr className="my-6 border border-gray-900/5 w-full max-w-[500px]" />

        <div className="max-w-[300px] flex flex-col gap-4 items-center justify-center text-edxred-500 w-full">
          <DogButton
            onClickFn={() => {
              state.actions.__reset__()
            }}
          >
            Activate More Tests
          </DogButton>

          <DogButton
            onClickFn={() => {
              navigate('/')
            }}
          >
            Back To Dashboard
          </DogButton>
        </div>

        <Confetti colors={['#8c0a0a']} numberOfPieces={500} width={width} height={height} recycle={false} />
      </div>
    )
  } else if (state.submitActivationCodesResult?.success === false) {
    return (
      <div className="flex flex-col justify-center items-center h-full gap-4">
        <ScreenTitle>Failed to Submit Activation Code{state.activationCodes.length > 1 ? `'s` : ''}</ScreenTitle>

        <div className="max-w-[300px] flex flex-col gap-4 items-center justify-center w-full text-edxred-500">
          <DogButton onClickFn={state.actions.submitActivationCodes}>Retry Submission</DogButton>

          <DogButton onClickFn={state.actions.__reset__}>Start Over</DogButton>
        </div>

        <hr className="my-6 border border-gray-900/5 w-full max-w-[500px]" />

        <ActivationCodesList />
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-col justify-center items-center h-full gap-4">
        <ScreenTitle>Activate Dog DNA Tests</ScreenTitle>

        {state.activationCodeInputValue !== null ? (
          <div className="w-full flex flex-col items-center gap-4 justify-center">
            <div className="text-lg font-semibold text-dogred-700">Activation Code</div>

            <div className="relative">
              <Input
                className="p-6 rounded-[30px] text-center text-2xl max-w-[300px]"
                disabled={state.activationCodeInputLoading}
                onChange={(e) => {
                  state.actions.setActivationCodeInputValue(e.target.value)
                }}
                placeholder="AB98F32"
                value={state.activationCodeInputValue}
              />

              {state.activationCodeInputLoading && (
                <div className="absolute right-5 top-3 animate-spin">
                  <ImSpinner10 className="text-brand" size={24} />
                </div>
              )}
            </div>

            {state.activationCodeInputError && (
              <div className="text-red-500 text-sm">{state.activationCodeInputError}</div>
            )}
          </div>
        ) : (
          <div className="max-w-[300px] w-full">
            <DogButton
              onClickFn={() => {
                state.actions.setActivationCodeInputValue('')
              }}
            >
              <span className="flex items-center gap-2 font-montserrat text-edxred-500 hover:text-white">
                <FaPlus /> Add Activation Code
              </span>
            </DogButton>
          </div>
        )}

        {state.activationCodes.length > 0 && <hr className="my-6 border border-gray-900/5 w-full max-w-[500px]" />}

        <ActivationCodesList />

        {state.activationCodes.length > 0 && (
          <div className="my-6 w-full max-w-[500px] px-4">
            <DogButton
              classNames="font-montserrat text-edxred-500 hover:text-white"
              disabled={state.submittingActivationCodes}
              onClickFn={state.actions.submitActivationCodes}
            >
              Submit Activation Code{state.activationCodes.length > 1 ? `'s` : ''}
            </DogButton>
          </div>
        )}

        {state.showSelectDogDialog && (
          <SelectDogDialog
            dogs={availableDogsForAssignment}
            onClose={state.actions.cancelActivationCode}
            onDogCreated={(newDog) => {
              state.actions.addDog(newDog)
              state.actions.addActivationCode({
                animalId: newDog.animalId!,
                code: state.showSelectDogDialog!.code,
                test: state.showSelectDogDialog!.test,
              })
            }}
            onSelectDog={(animalId) => {
              state.actions.addActivationCode({
                animalId: animalId,
                code: state.showSelectDogDialog!.code,
                test: state.showSelectDogDialog!.test,
              })
            }}
            title="Which dog would you like to assign to this activation code?"
          />
        )}
      </div>
    </>
  )
}
