import { create } from 'zustand'
import { DogDetail, DogList, getDogById, getDogList } from '../../services/edx-api.ts'

export interface DashboardScreenState {
  actions: {
    closeDogForm: () => void
    loadDogs: () => void
    selectDog: (dogId?: DogDetail['id']) => void
    // if adding new dog then dogId will be undefined
    showDogForm: (dogId?: DogDetail['id']) => void
  }
  dogs: DogList
  dogsLoading: boolean
  selectedDog?:
    | {
        dog: undefined
        loading: true
      }
    | {
        dog: DogDetail
        loading: false
      }
  showingDogForm?: { dogId?: DogDetail['id'] } | undefined
}

export const useDashboardScreenState = create<DashboardScreenState>((set, get) => ({
  actions: {
    closeDogForm: () => {
      set({
        showingDogForm: undefined,
      })

      get().actions.loadDogs()
    },

    loadDogs: () => {
      set({
        dogsLoading: true,
      })

      getDogList()
        .then((data) => {
          const { selectedDog } = get()

          if (selectedDog) {
            const updatedSelectedDog = data.find((dog) => dog.id === selectedDog.dog?.id)

            if (!updatedSelectedDog) {
              set({
                selectedDog: undefined,
              })
            } else {
              selectedDog.dog = updatedSelectedDog
            }
          }

          set({
            dogs: data || [],
            dogsLoading: false,
            selectedDog,
          })
        })
        .catch(() => {
          set({
            dogsLoading: false,
          })
        })
    },

    selectDog: async (dogId) => {
      if (dogId) {
        set({
          selectedDog: {
            dog: undefined,
            loading: true,
          },
        })

        const selectedDog = await getDogById(dogId)

        if (selectedDog) {
          set({
            selectedDog: {
              dog: selectedDog,
              loading: false,
            },
          })
        } else {
          set({
            selectedDog: undefined,
          })
        }
      } else {
        set({ selectedDog: undefined })
      }
    },
    showDogForm: (dogId) => {
      set({
        showingDogForm: { dogId },
      })
    },
  },
  dogs: [],
  dogsLoading: false,
  selectedDog: undefined,
  showingDogForm: undefined,
}))
