import { BrowserRouter } from 'react-router-dom'
import { Toaster } from '@edx/react-common'
import { AppRoutes } from './AppRoutes.tsx'
import { AuthWrapper } from '@edx/web-auth'
import { Environment } from '../config/environment.ts'
import logo from '/alphadog-by-etalon.png'
import mainImage from '/alphadog-square.png'
import { DogButton } from './components/DogButton.tsx'
import { TosAgreementDialog } from './components/TosAgreementDialog'
import { SourceApplication } from '@edx/api/dist/services/api'

export function App() {
  return (
    <AuthWrapper
      app={SourceApplication.DGX}
      authWebUrl={Environment.AUTH_WEB_URL}
      Components={{
        Agreement: TosAgreementDialog,
        Button: (props) => (
          <DogButton children={props.children} disabled={props.disabled} onClickFn={props.onClick} type={props.type} />
        ),
      }}
      baseApiUrl={Environment.BASE_API_URL}
      logo={logo}
      mainImage={mainImage}
      styles={{
        asideBackgroundClassname: 'bg-gradient-to-b from-doggradient-500 via-doggradient-300 via-81% to-white',
        backgroundClassname: 'bg-gradient-to-b from-doggradient-500 via-doggradient-300 via-81% to-white',
      }}
    >
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>

      <Toaster />
    </AuthWrapper>
  )
}
