import { Environment } from '../../config/environment.ts'
import { Api } from '@edx/ecommerce-api'

export const ecommerceApi = new Api({
  baseURL: Environment.ECOMMERCE_API_URL,
})

export const setEcommerceApiAuthHeader = (params: { token: string }) => {
  ecommerceApi.instance.defaults.headers.common['Authorization'] = `Bearer ${params.token}`
}
