import React, { ReactNode, useState } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@edx/react-common'
import { DogButton } from '../DogButton.tsx'
import { FaChevronLeft, FaPlus } from 'react-icons/fa6'
import { DogForm } from '../DogForm.tsx'
import { DogDetail } from '../../services/edx-api.ts'
import { AnimalAge } from '../AnimalAge.tsx'
import { getDivStyleForDogAvatarBg } from '../../utils/get-div-style-for-dog-avatar-bg.ts'

interface SelectDogDialogProps {
  dogs: DogDetail[]
  onClose(): void
  onDogCreated(dog: DogDetail): void
  onSelectDog(animalId: string): void
  title?: ReactNode
}

export function SelectDogDialog({ dogs, onClose, onDogCreated, onSelectDog, title }: SelectDogDialogProps) {
  const [showAddDogForm, setShowAddDogForm] = useState(!dogs.length)

  return (
    <Dialog open onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="bg-gradient-to-b from-doggradient-300 to-doggradient-100 shadow-md border-2 border-white backdrop-blur-[50px] rounded-3xl bg-[unset]">
        <DialogHeader>
          <DialogTitle className="text-2xl">
            {showAddDogForm ? (
              <div className="flex gap-2 items-center">
                <FaChevronLeft className="cursor-pointer" onClick={() => setShowAddDogForm(false)} /> Add Dog
              </div>
            ) : (
              title
            )}
          </DialogTitle>
        </DialogHeader>

        {showAddDogForm ? (
          <DogForm
            onCancel={() => setShowAddDogForm(false)}
            onComplete={(dog) => {
              onDogCreated(dog)
            }}
          />
        ) : (
          <div className="flex flex-col items-center gap-2 justify-center max-h-[500px]">
            <div className="overflow-y-auto w-full">
              {dogs.map((dog) => (
                <div
                  className="flex justify-between gap-6 w-full cursor-pointer hover:bg-gray-200/30 rounded-lg p-2"
                  key={dog.id}
                  onClick={() => {
                    onSelectDog(String(dog.animalId))
                  }}
                >
                  <div
                    className="h-24 w-36 border shadow bg-white rounded-lg"
                    style={getDivStyleForDogAvatarBg(dog.avatar?.url)}
                  ></div>
                  <div className="flex-1 flex flex-col text-dogred-700">
                    <div className="text-lg font-semibold">{dog.name}</div>
                    <div className="uppercase">{dog.breed}</div>
                    <div className="font-light text-sm">
                      <AnimalAge dob={dog.dateOfBirth ?? ''} />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex justify-between gap-6 w-full">
              <DogButton onClickFn={() => setShowAddDogForm(true)}>
                <FaPlus className="mr-1" /> Add New Dog
              </DogButton>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}
