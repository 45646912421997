import { edxApi } from './edx-api.ts'
import { ActivationCodeGetProductsByActivationCodesResponse, ActivationCodeStatus } from '@edx/api/dist/services/api'
import { ActivationCodeUseModel, AnimalType } from '@edx/api/src/services/api'

export type TTest = {
  description: string
  id: string
  label: string
}

export type TActivationCodeDetail = {
  errorMesaage?: string
  test?: TTest
  valid: boolean
}

/**
 * Call the server to get the details of an activation code.
 * This validates a code and returns the details of the code ie:
 * what test is the code for, has it been used, etc.
 */
export async function getActivationCodeDetail(code: string): Promise<TActivationCodeDetail> {
  let test: TTest = {
    description: '',
    id: '0',
    label: 'Unknown Test',
  }

  try {
    const { data } = await edxApi.api.activationCodesValidateDetail(code)

    if (data.product) {
      test = {
        description: data.product.description ?? '',
        id: data.product.id?.toString() ?? '0',
        label: data.product.name ?? 'Unknown Test',
      }
    }

    if (data.animalType !== AnimalType.Dog || data.product?.animalType !== AnimalType.Dog) {
      return {
        errorMesaage: 'The activation code entered is not for a Dog DNA test',
        test,
        valid: false,
      }
    }

    return {
      errorMesaage: data.status === ActivationCodeStatus.Active ? '' : 'Activation code is not active',
      test,
      valid: data.status === ActivationCodeStatus.Active,
    }
  } catch (e) {
    return {
      errorMesaage: 'Invalid code',
      valid: false,
    }
  }
}

/**
 * Submit activation codes and animal assignments.
 */
export async function submitActivationCodes(
  activations: {
    activationCode: string
    animalId: string
  }[],
): Promise<{ success: boolean }> {
  const codes: ActivationCodeUseModel[] = activations.map((activation) => ({
    activationCode: activation.activationCode,
    animalId: activation.animalId,
  }))

  const response = await edxApi.api.activationCodesUpdate({
    codes,
  })

  return {
    success: response.status >= 200 && response.status < 300,
  }
}
